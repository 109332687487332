import React, { useState } from "react";
import styles from "./Brands.module.css";
import { ReactComponent as Deciwood } from "./assets/Deciwood.svg";
import { ReactComponent as Eatfit } from "./assets/Eatfit.svg";
import { ReactComponent as Skinq } from "./assets/Skinq.svg";
import { ReactComponent as White } from "./assets/White.svg";
import { ReactComponent as Mars } from "./assets/Mars.svg";
import { ReactComponent as Bellavita } from "./assets/Bellavita.svg";
import { ReactComponent as BareAnatomy } from "./assets/BareAnatomy.svg";
import { ReactComponent as Rabitat } from "./assets/Rabitat.svg";
import { ReactComponent as MetaMan } from "./assets/MetaMan.svg";
import { ReactComponent as Innovist } from "./assets/Innovist.svg";
import { ReactComponent as BSC } from "./assets/BSC.svg";
import { ReactComponent as Unbottle } from "./assets/Unbottle.svg";
import { ReactComponent as Khojcraft } from "./assets/Khojcraft.svg";
import { ReactComponent as Womenlikeu } from "./assets/Womenlikeu.svg";
import { ReactComponent as Zlade } from "./assets/Zlade.svg";
import { ReactComponent as Beco } from "./assets/Beco.svg";
import { ReactComponent as Snitch } from "./assets/Snitch.svg";
import { ReactComponent as Juicychemistry } from "./assets/Juicychemistry.svg";
import Ravel from "./assets/Ravel.png";
import Thrive from "./assets/Thrive.png";
import Teatrunk from "./assets/Teatrunk.png";
import { ReactComponent as Gynoveda } from "./assets/gynoveda.svg";
import { ReactComponent as Perfora } from "./assets/perfora.svg";
import { ReactComponent as Kreo } from "./assets/Kreo.svg";
import { ReactComponent as Solethreads } from "./assets/Solethreads.svg";
import { ReactComponent as DotandKey } from "./assets/DotandKey.svg";
import { ReactComponent as Mokobara } from "./assets/Mokobara.svg";
import { ReactComponent as Namhya } from "./assets/Namhya.svg";
import { ReactComponent as Happilo } from "./assets/Happilo.svg";
import { ReactComponent as Ziva } from "./assets/Ziva.svg";
import { ReactComponent as Damensch } from "./assets/Damensch.svg";
import { ReactComponent as Letsshave } from "./assets/Letsshave.svg";
import { ReactComponent as Zouk } from "./assets/Zouk.svg";
import { ReactComponent as Minimalist } from "./assets/Minimalist.svg";
import { ReactComponent as Mcaffeiene } from "./assets/Mcaffeiene.svg";
import NasherMiles from "./assets/Nasher miles.png";
import MamaEarth from "./assets/MamaEarth.png";
import Bombae from "./assets/Bombae.png";
import Totem from "./assets/Totem.png";
import Ugaoo from "./assets/Ugaoo.png";
import Oracura from "./assets/Oracura.png";
import { ReactComponent as Ldu } from "./assets/Ldu.svg";
import Marquee from "react-fast-marquee";
import AnimationDot from "../Animations/AnimationDot";

const Brands = () => {

  const [Desktop, setDesktop] = useState(window.innerWidth > 600)
  /* { brandType: "svg", component: <Deciwood /> },
   { brandType: "svg", component: <Eatfit /> },
   { brandType: "svg", component: <White /> },
   { brandType: "svg", component: <Bellavita /> },
   { brandType: "svg", component: <BareAnatomy /> },
   { brandType: "svg", component: <MetaMan /> },
   { brandType: "svg", component: <Unbottle /> },
   { brandType: "svg", component: <Khojcraft /> },
   { brandType: "svg", component: <Womenlikeu /> },
   { brandType: "png", component: Ravel },
   { brandType: "png", component: Thrive },
   { brandType: "svg", component: <Zlade /> },
   { brandType: "svg", component: <Beco /> },
   { brandType: "svg", component: <Snitch /> },
   { brandType: "png", component: Teatrunk },
   { brandType: "svg", component: <Juicychemistry /> },*/

  const brandDesktop1: any = [
    { brandType: "svg", component: <Perfora /> },
    // { brandType: "svg", component: <Mokobara /> },
    { brandType: "svg", component: <Solethreads /> },
    { brandType: "svg", component: <BSC /> },
    // { brandType: "svg", component: <Ziva /> },
    { brandType: "svg", component: <Damensch /> },
    // { brandType: "svg", component: <Letsshave /> },
    // { brandType: "svg", component: <Zouk /> },
    // { brandType: "svg", component: <Minimalist /> },
    { brandType: "svg", component: <Kreo /> },
    { brandType: "png", component: Oracura },

  ]
  
  const brandDesktop2: any = [
    // { brandType: "svg", component: <Gynoveda /> },
    // { brandType: "svg", component: <DotandKey /> },
    // { brandType: "svg", component: <Happilo /> },
    // { brandType: "svg", component: <Innovist /> },
    // { brandType: "svg", component: <Mcaffeiene /> },
    // { brandType: "svg", component: <Rabitat /> },
    // { brandType: "svg", component: <Skinq /> },
    // { brandType: "svg", component: <Namhya /> },
    // { brandType: "svg", component: <Mars /> },
    { brandType: "png", component: Bombae },
    { brandType: "png", component: MamaEarth },
    { brandType: "png", component: NasherMiles },
    { brandType: "png", component: Totem },
    { brandType: "png", component: Ugaoo },
  ];

  const brandMobile1: any = [
    { brandType: "svg", component: <Perfora /> },
    // { brandType: "svg", component: <Mokobara /> },
    { brandType: "svg", component: <BSC /> },
    // { brandType: "svg", component: <Ziva /> },
    { brandType: "png", component: Bombae },
  ]
  
  const brandMobile2: any = [
    { brandType: "svg", component: <Damensch /> },
    // { brandType: "svg", component: <Letsshave /> },
    // { brandType: "svg", component: <Zouk /> },
    // { brandType: "svg", component: <Minimalist /> },
    { brandType: "svg", component: <Kreo /> },
    { brandType: "png", component: Totem },
  ]
  
  const brandMobile3: any = [
    // { brandType: "svg", component: <Gynoveda /> },
    // { brandType: "svg", component: <DotandKey /> },
    // { brandType: "svg", component: <Happilo /> },
    // { brandType: "svg", component: <Innovist /> },
    // { brandType: "svg", component: <Mcaffeiene /> },
    { brandType: "svg", component: <Solethreads /> },
    { brandType: "png", component: Ugaoo },
    { brandType: "png", component: Oracura },
  ]
  const brandMobile4: any = [
    // { brandType: "svg", component: <Rabitat /> },
    // { brandType: "svg", component: <Skinq /> },
    // { brandType: "svg", component: <Namhya /> },
    // { brandType: "svg", component: <Mars /> },
    { brandType: "png", component: MamaEarth },
    { brandType: "png", component: NasherMiles },
  ]

  return (
    <div className={styles.brands_section}>
      <div className={styles.AnimationDot}>
        <AnimationDot />
      </div>
      <div className={styles.brands_subsection}>
        <p>Brand’s Growing with us</p>
        <div className={styles.brandgrid}>
          {Desktop ? (
            <>
              <div className={styles.brands}>
                <Marquee direction="right" speed={90}>
                  {brandDesktop1.map((brand: any, index: number) => (
                    <div className={styles.brand} key={index}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
              <div className={styles.brands}>
                <Marquee speed={90}>
                  {brandDesktop2.map((brand: any, index: number) => (
                    <div className={styles.brand} key={index}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
            </>
          ) : (
            /* Mobile Marquees */
            <>
              <div className={styles.brands}>
                <Marquee direction="right" speed={90}>
                  {brandMobile1.map((brand: any, index: number) => (
                    <div className={styles.brand}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
              <div className={styles.brands}>
                <Marquee direction="left" speed={90}>
                  {brandMobile2.map((brand: any, index: number) => (
                    <div className={styles.brand}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
              <div className={styles.brands}>
                <Marquee direction="right" speed={90}>
                  {brandMobile3.map((brand: any, index: number) => (
                    <div className={styles.brand}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
              <div className={styles.brands}>
                <Marquee direction="left" speed={90}>
                  {brandMobile4.map((brand: any, index: number) => (
                    <div className={styles.brand}>
                      {brand.brandType === "png" ? (
                        <img src={brand.component} style={{width: '100%', borderRadius: '28px'}} alt="" />
                      ) : (
                        brand.component
                      )}
                    </div>
                  ))}
                </Marquee>
              </div>
            </>

          )}
        </div>
      </div>
    </div>
  );
};

export default Brands;
